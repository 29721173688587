.authSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 100px 0;

  > h3 {
    color: #074dff;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
  }
}
