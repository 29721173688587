.container {
  position: absolute;
  top: -27px;
  background: #a3aed0;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  padding: 3px 8px;
  min-width: 44px;
  min-height: 20px;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.02em;
  margin-bottom: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: #a3aed0 transparent transparent transparent;
  }
}
.active {
  background: #1b2559;
  color: #cbd5ed;
  &:after {
    border-color: #1b2559 transparent transparent transparent;
  }
}
