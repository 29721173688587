.authForm {
  width: 100%;
  max-width: 532px;
  padding-inline: 16px;

  .authCard {
    background-color: #fff;
    padding: 40px 32px 32px 32px;
    box-shadow: 0px 0px 12px #00000014;
    border-radius: 10px;
    text-align: left;

    @media screen and (max-width: 420px) {
      padding: 40px 8px 32px;
    }

    .socialInput {
      width: 100%;
      height: 50px;

      display: flex;
      flex-direction: row;
      align-items: center;

      position: relative;

      color: #92989c;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      background-color: #fff;
      background-clip: padding-box;

      border-radius: 5px;
      border: 1px solid #92989c;

      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      > .socialInputIcon {
        padding: 11px;
        background: #e2e2e2;

        border-radius: 4px 0px 0px 4px;
      }

      > .validInput {
        height: 20px;

        position: absolute;
        right: 8px;
      }

      > input {
        height: 100%;
        border: none;

        flex-grow: 1;
        padding: 15px 35px 15px 15px;

        border-radius: 4px;
      }
    }

    .socialInput > input::placeholder {
      @media screen and (max-width: 420px) {
        font-size: 11px;
      }
    }

    .profilePhoto {
      display: flex;
      align-items: center;

      .customFileInput {
        cursor: pointer;
        height: 70px;

        &:focus {
          outline: #10aafb auto 1px;
        }
      }

      .uploadControl {
        display: flex;
        flex-direction: column;

        .uploadPhotoBtn {
          border: none;
          background: transparent;

          padding: 0;
          margin-top: 2px;

          color: #074dff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 17px;
          text-decoration-line: underline;
        }

        span {
          color: #2d2d2d;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 17px;
        }
      }

      input {
        display: none;
      }

      img {
        width: 72px;
        height: 72px;

        border-radius: 50%;

        margin-right: 16px;
      }
    }

    > h2 {
      color: #001e36;
      text-align: center;

      font-size: 32px;
      font-weight: 700;
    }

    .authInput {
      display: block;
      width: 100%;
      height: 50px;
      padding: 15px;

      color: #92989c;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      background-color: #fff;
      background-clip: padding-box;

      border-radius: 5px;
      border: 1px solid #92989c;

      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }

    .authInput:focus {
      outline: none;
      border-color: #10aafb;
    }

    .inputError {
      border-color: #ef3451 !important;
    }

    .authLabel {
      color: #2d2d2d;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
    }

    .authPrimaryBtn {
      background: #074dff;
      border: none;
      padding: 18px;

      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 700;

      i {
        margin-right: 7px;
      }
    }

    .authSecondaryBtn {
      margin: 84px 0 0 0;
      text-align: center;
      font-weight: bold;
    }

    .labelError {
      color: #fb024e;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.registerSuccessModal {
  .modalHeader {
    border-bottom: none;
  }

  .modalContent {
    padding-bottom: 40px;
  }

  .modalDialog {
    max-width: 600px;
  }

  .modalTitle {
    color: #074dff;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .modalText {
    color: #6e6e6e;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.blackLink {
  color: #001e36;
  font-size: 18px;
  text-decoration: underline;
  transition: 0.3s;

  &:hover {
    color: #10aafb;
    text-decoration: underline;
  }
}
