.videoStatsContainer {
  background-color: #fff;
  padding: 20px 14px 10px;

  h1,
  p {
    margin-bottom: 0;
  }

  > h1 {
    color: #1b2559;
    font-family: 'Product Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .videoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 181px;
    margin: 12px 0 6px;

    img {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      max-width: 350px;
    }
  }

  .videoInfo {
    text-align: center;

    h1 {
      color: #1b2559;
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }

    p {
      color: #a3aed0;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }

  .videoDescription {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      margin-right: 16px;

      p {
        color: #a3aed0;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
        text-wrap: nowrap;
      }

      h1 {
        color: #1b2559;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 0;
      }
    }
  }
}
