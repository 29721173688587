.container {
  height: 100%;

  padding: 16px 14px 8px;
  background: #fff;
  border-radius: 12px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    span {
      padding-left: 11px;
      margin-bottom: -15px;

      h1 {
        color: #2b3674;
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.4px;
        margin-bottom: 8px;
      }

      p {
        color: #1b2559;
        font-family: Inter, sans-serif;
        font-size: 21.939px;
        font-style: normal;
        font-weight: 900;
        line-height: 25.596px;
        margin-bottom: 0;
      }
    }
  }
}
