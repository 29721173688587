.vuz-pay-analytics {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  .divider {
    border-left: 1px solid #a3aed0;
    height: 50px;
    margin: 0 10px;
  }

  p {
    font-size: 14px !important;
  }
}
