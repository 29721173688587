@use 'src/styles/mixins' as *;

.progressAlertSection {
  display: flex;
  flex-direction: row;

  padding: 31px 32px 30px 32px;

  border-radius: 5px;
  background: #fff;

  max-width: 900px;
  margin: 0 auto;

  @include small-screens {
    padding: 15px;
  }

  > img {
    margin-right: 25px;
  }

  &.blue {
    border: 1px solid #074dff;
  }

  &.red {
    border: 1px solid #fb024e;
  }

  &.yellow {
    border: 1px solid #ffc14a;
  }

  &.green {
    border: 1px solid #33d142;
  }

  .alertInfo {
    background-color: transparent;

    .alertTitle {
      color: #001e36;
      font-size: 16px;
      font-weight: 700;

      margin-bottom: 12px;
    }

    .alertText {
      color: #001e36;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }
}
