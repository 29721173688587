.container {
  width: 100%;

  .videoInfo {
    margin: 21px 0 0 20px;
    display: flex;
    align-items: center;

    .videoContainer {
      width: 140px;
      height: 75px;

      margin-right: 21px;
      border-radius: 8px;
      overflow: hidden;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    h1 {
      color: #2b3674;
      font-family: Inter, sans-serif;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: -0.68px;
      margin-bottom: 10px;
    }

    p {
      color: #a3aed0;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.28px;
      margin-bottom: 0;
    }
  }

  .tabsContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #a3aed0;
    margin: 35px 0 29px;

    .tabs {
      display: flex;
      padding-left: 26px;

      button {
        padding-bottom: 16px;
        font-family:
          Segoe UI,
          sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        border: none;
        background: transparent;
        margin-right: 60px;
        color: #a3aed0;
      }

      .activeTab {
        color: #074dff;
      }

      .activeBorder {
        width: calc(100% - 60px);
        height: 8px;
        border-radius: 71px 51px 0px 0px;
        background: #074dff;
      }
    }
  }

  .widgets {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    height: 200px;

    > div {
      padding: 12px 24px;
      border-radius: 16px;
      background: #ffffff;
    }
  }
}
