.container {
  height: 100%;
  padding: 16px 14px 8px 19px;
  background: #fff;
  border-radius: 12px;

  .info {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 40px;

    .header {
      .bonus {
        display: flex;
        align-items: center;

        .bonusAmount {
          color: #1b2559;
          font-family: Inter, sans-serif;
          font-size: 24px;
          font-weight: 700;

          margin-right: 15px;
        }

        .onTrack {
          display: flex;
          align-items: center;

          > svg {
            width: 16px;
            height: 16px;
            background: #1b2559;
            border-radius: 50%;

            margin-right: 7px;
          }

          > span {
            color: #1b2559;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }

    span {
      color: #1b2559;
      font-family: Inter, sans-serif;
      font-style: normal;

      &:first-child {
        font-size: 20px;
        font-weight: 700;
        line-height: 15.5px;
        letter-spacing: -0.4px;
        display: flex;
        align-items: center;

        svg {
          margin-left: 17px;
        }
      }

      &:nth-child(2) {
        font-size: 24px;
        font-weight: 900;
        line-height: 25.596px;
      }

      &:nth-child(3) {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.32px;

        div {
          border-radius: 46px;
          background: #1b2559;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 7px;
        }
      }
    }

    .milestoneInfo {
      border-radius: 41px;
      border: 2px solid #b0bbd5;
      padding: 10px;
      max-width: 200px;
      max-height: 82px;

      h1,
      p {
        color: #1b2559;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        margin-bottom: 0;
      }

      p {
        color: #b0bbd5;
        font-size: 12px;
        font-weight: 400;
        text-wrap: nowrap;
      }
    }
  }

  .milestoneContainer {
    position: relative;
    border-radius: 15.072px;
    background: #cbd5ed;
    height: 24.348px;
    margin-left: 74px;
    display: flex;

    .progress {
      position: absolute;
      height: 100%;
      border-radius: 60.29px;
      background: linear-gradient(270deg, #a3aed0 0%, #3c4b64 105.28%);
    }

    > div {
      position: relative;
      width: 33.3%;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 12.986px;
        font-style: normal;
        font-weight: 700;
        line-height: 22.725px;
        letter-spacing: -0.26px;
        border-radius: 45.449px;
        border: 1.623px solid #1b2559;
        height: 100%;
        width: 25px;
      }

      p {
        position: absolute;
        bottom: -28px;
      }

      .firstPoint {
        left: 0;
      }
    }

    .active {
      border-radius: 60.29px;
      background: linear-gradient(270deg, #a3aed0 0%, #3c4b64 105.28%);

      span {
        border-radius: 53.69px;
        border: 2.876px solid #eaeffb;
        background: var(--main, #1b2559);
        color: #a3aed0;
        font-size: 15.34px;
        line-height: 26.845px;
        letter-spacing: -0.307px;
      }
    }
  }

  p {
    color: #cbd5ed;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.24px;
    margin-bottom: 0;
  }

  > p {
    margin: 0 0 0 30px;

    span {
      margin-left: 11px;
    }
  }
}
