.layout {
  height: 100%;
  display: flex;
  flex-direction: row;

  .sideBarContainer {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 0;
    width: 290px;
    height: 100vh;
    background: #fff;
    padding: 36px 10px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 100px;

      img {
        width: 128px;
        transition: 0.3s ease;
      }
    }

    .menuItem,
    .logOutContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      margin-bottom: 12px;
      width: 100%;
      cursor: pointer;

      p {
        color: #a3aed0;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 31.6px;
        padding: 0 0 0 15.8px;
        margin-bottom: 0;
      }

      svg {
        width: 27px;
        height: 27px;

        path {
          fill: #a3aed0;
        }
      }
    }

    .selectedMenuItem,
    .menuItem:hover,
    .logOutContainer:hover {
      border-radius: 5.644px;
      background: #074dff;
      p {
        color: #fff;
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }

    .logOutContainer {
      margin-bottom: 0;
      svg {
        margin-right: 3px;
      }
    }

    @media screen and (max-width: 1280px) {
      width: auto;

      .logo {
        img {
          width: 96px;
        }
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      p {
        display: none;
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 0;

      > div {
        display: none !important;
      }
    }
  }

  .mainSection {
    display: flex;
    width: 100%;
    overflow: auto;
    background: #f4f7fe;
    padding: 47px 20px 51px;

    @media screen and (max-width: 768px) {
      padding: 110px 10px 150px;
    }
  }
}
