.container {
  width: 90px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 90px;
  background: #e2ecfb;

  padding: 10px 15px;
  margin: 0 auto;

  > svg:first-of-type {
    margin-bottom: 2px;
  }
}
