@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html {
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif;

  height: 100%;
  display: flex;

  background-color: #f7f7f7;
  color: #001e36;
  box-sizing: border-box;
}

button,
button:focus,
input[type='button']:focus {
  outline: none;
}

a:hover {
  text-decoration: none !important;
}

div#root {
  width: 100%;
}

.btn-blue {
  background-color: #10aafb;
  padding: 19px 20px;
  text-align: center;
  display: inline-block;
  font-size: 22px;
  color: #fff;
  border-radius: 4px;
  border: none;
  /* transition: 0.3s; */
  transition: all 0.3s;
}

.btn-blue:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px rgba(16, 170, 251, 0.9);
  text-decoration: none;
}

.btn-blue.btn-block {
  display: block;
  width: 100%;
}

.btn-blue.disabled {
  background: #ccc;
  color: #fff;
}

.btn-blue:focus {
  outline: none;
}

.btn-blue:disabled {
  background-color: #90d9ff;
  color: #52b6e8;
}

.btn-blue-sm {
  padding-top: 9px;
  padding-bottom: 7px;
  font-size: 18px;
}

.clickable {
  cursor: pointer;
}

.logo1 {
  height: 100px;
}

.custom-h3 {
  font-size: 36px;
}

.text-white {
  color: #fff;
}

.custom-h2 {
  font-size: 45px;
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.only-mobile {
  display: none;
}

.custom-navbar {
  background-color: #001e36 !important;
  min-height: 100px;
  padding-left: 45px;
  padding-right: 45px;
}

.logo2 {
  height: 50px;
}

.logo-text {
  color: #fff;
  margin-left: 12px;
  position: relative;
  top: 7px;
  font-size: 34px;
}

.navbar-brand a:hover {
  text-decoration: none;
}

a.dropdown-item a {
  color: #001e36;
}

a.dropdown-item:hover a {
  color: #001e36;
  text-decoration: none;
}

a.dropdown-item:active a {
  color: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #10aafb;
  color: #fff;
}

.logo-text:hover {
  text-decoration: none;
}

.right-nav {
  margin-top: 10px;
}

.dropdown-menu {
  min-width: 5rem;
}

.dropdown-toggle::after {
  position: relative;
  top: 3px;
  right: -2px;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.step-title > div {
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: #d9d9d9;

  color: #000;
  font-size: 12px;
  font-weight: 700;

  margin-right: 8px;
}

.progress-alert-section .alert {
  border-width: 2px;
  padding: 30px 25px;
  border-radius: 8px;
  border-left-width: 8px;
}

.modal-content {
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.sm-icon-margin-1 {
  margin-right: 7px;
}

.hidden {
  display: none;
}

.pad-fix-1 {
  padding-left: 17px;
  padding-right: 17px;
}

.progress {
  border-radius: 10px;
  height: 10px;
}

.progress-bar {
  background: #074dff;
}

.user-avatar {
  height: 35px;
  border-radius: 50px;
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.percentage-text {
  color: #0faafb;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  line-height: 1.2;
}

.uploading-text {
  color: #6797bf;
  font-size: 18px;
  text-align: center;
  margin-bottom: 0;
  line-height: 1.2;
}

.remove-bg-image {
  background-image: unset;
}

.blue-border {
  border-color: #0faafb;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-transparent {
  background-color: transparent;
  background-image: none;
}

.btn-submit-for-review {
  width: 450px;
}

.visibility-hidden {
  visibility: hidden;
}

.custom-file {
  height: 80px;
  cursor: pointer;
}

.custom-file-label {
  border: 2px solid #ccc;
  border-radius: 4px;
  height: 70px;
  cursor: pointer;
  padding-top: 20px;
  color: #001e36;
}

.custom-file-label::after {
  height: 66px;
  padding: 1.3rem 0.75rem;
  cursor: pointer;
}

.dimensions-txt {
  color: #b9b9b9;
  font-size: 17px;
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  left: 0;
  margin-bottom: 0;
}

.modal-content {
  width: 500px;
  margin: 0 auto;
}

.modal-content {
  padding: 0 32px 86px 32px !important;
}

.modal-body {
  padding: 0 !important;
}

.dropdown-toggle::after {
  vertical-align: 8px !important;
}

.dropdown-menu {
  transform: translate3d(-35px, 42px, 0px) !important;
}

.nav-link {
  padding: 0 !important;
}

#main-video,
#image-canvas {
  display: none;
}

div#root:has(.upload-section) {
  overflow: hidden;
}

div#root:has(.uploads-section) {
  overflow: hidden;
}
