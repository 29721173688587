.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .topWidgetsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    > div {
      min-height: 200px;

      &:nth-child(1) {
        margin-right: 20px;
        width: 40%;
      }

      &:nth-child(2) {
        width: 60%;
      }
    }
  }

  > div {
    &:first-child,
    &:nth-child(4),
    &:nth-child(5) {
      grid-column: 1/3;
    }

    &:first-child {
      display: flex;
      justify-content: space-between;

      > p {
        color: #2b3674;
        font-family: Inter, sans-serif;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: -0.68px;
      }
    }
  }

  .tabsWidgetContainer {
    padding-bottom: 20px;
  }

  .videoEarningsTable {
    table {
      thead {
        th {
          &:nth-child(2) {
            height: 40px;
          }

          &:nth-child(3) {
            width: 20%;
          }
        }
      }

      tbody {
        td {
          &:nth-child(2) {
            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
          }

          &:nth-child(3) {
            width: 20%;
            text-align: left;
          }
        }
      }
    }
  }

  .vuz-pay-link-container {
    margin: 0 25px;
  }

  .vuz-pay-link-header {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;

    h1 {
      color: #2b3674;
      font-family: Inter, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  .vuz-pay-link-description {
    color: #a3aed0;
    font-size: 14px;
    font-style: normal;
    margin-bottom: 2rem;
  }
}
