.customTooltip {
  height: 194px;
  width: 167px;
  background: #37375c;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 7px 10px 18px;
  border-radius: 8px;
  margin-left: 15px;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0.3px;
    text-align: center;
    color: #fff;
  }

  table {
    width: 100%;
    height: 100%;

    th {
      font-family: 'Poppins', sans-serif;
      font-size: 10px;
      font-weight: 300;
      line-height: 12px;
      letter-spacing: 0.3px;
      text-align: left;
      color: #fff;
    }

    td {
      color: #fff;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0.3px;
      text-align: left;
    }

    td:first-child {
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0.3px;
      text-align: left;
    }
  }
}
