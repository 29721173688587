.periodSelect {
  position: relative;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 19px;
  border-radius: 7.114px;
  background: #1b2559;
  cursor: pointer;

  p {
    margin-left: 9px;
    color: #eaeffb;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.39px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
  }

  > div {
    width: 190px;
    position: absolute;
    top: 38px;
    right: 0;
    border-radius: 6px;
    border: 0.717px solid #dde1eb;
    background: #fff;
    z-index: 33;

    > div {
      cursor: pointer;
      padding: 15px;
      color: #60656e;
      font-family: Inter, sans-serif;
      font-size: 11.473px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-wrap: nowrap;

      &:nth-child(5),
      &:nth-child(7) {
        border-bottom: 1.5px solid #d9d9d9;
      }

      &:hover {
        background: #fafafa;
      }
    }
  }
}
