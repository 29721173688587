.tableWidgetContainer {
  background-color: #fff;
  padding: 20px 10px 27px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 25px 10px;

    h1 {
      color: #2b3674;
      font-family: Inter, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 0;
    }

    div {
      border-radius: 10px;
      background: #f4f7fe;
      padding: 7px;

      &:hover {
        cursor: pointer;
        background: #074dff;

        svg {
          path {
            fill: #f4f7fe;
          }
        }
      }
    }
  }

  table {
    width: 100%;
    height: 100%;

    thead {
      min-height: 40px;
      margin-bottom: 5px;

      tr {
        border-bottom: 1px solid #a3aed0;
      }

      th {
        text-align: center;
        color: #a3aed0;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        &:first-child {
          width: 30px;
        }
      }
    }

    tbody {
      display: block;
      max-height: 250px;
      overflow: auto;

      tr {
        min-height: 40px;
      }

      td {
        text-align: center;
        color: #2b3674;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin-right: 24px;
        padding: 7px;

        &:first-child {
          width: 30px;
          color: #a3aed0;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }

        &:last-child {
          min-width: 37px;
        }
      }
    }

    thead,
    tbody > tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
}
