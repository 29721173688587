.influencerContainer {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 22px 47px;

  .influencerIcon {
    border-radius: 50%;
    background: #f4f7fe;
    width: 127.225px;
    height: 127.225px;
    flex-shrink: 0;

    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .influencerInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 13px 0 19px;

    h1 {
      color: #1b2559;
      font-family: Inter, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 0;
    }

    span {
      display: flex;
      align-items: center;

      p {
        color: #a3aed0;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.28px;
        margin: 0 4px;
      }
    }
  }

  .description {
    display: flex;
    margin-left: 16px;

    div {
      margin-right: 16px;

      p {
        color: #a3aed0;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 0;
        text-wrap: nowrap;
      }

      h1 {
        color: #1b2559;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 0;
      }
    }
  }
}
