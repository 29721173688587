.container {
  height: 100%;
  max-height: 330px;

  grid-column-gap: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  padding: 24px;
  border-radius: 16px;
  background: #fff;

  .info,
  .chartWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-child {
      align-items: flex-end;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
      padding-left: 12px;

      list-style: none;

      li {
        display: flex;
        align-items: center;

        color: #1b2559;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        margin: 0 3px 9px 0;

        .dot::before {
          content: '•';
          margin-right: 0.5em;
        }
      }

      p {
        color: #1b2559;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 9px;
      }
    }
  }
}
