.tableContainer {
  width: 100%;
  overflow: auto;
  position: relative;

  table {
    width: 100%;
    height: 85%;

    thead {
      margin-bottom: 10px;

      tr {
        border-bottom: 1px solid #a3aed0;
      }

      th {
        text-align: center;
        color: #a3aed0;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            display: block;
            margin-left: 10px;
            width: 24px;
            height: 24px;
          }
        }

        &:first-child {
          width: 30px;
        }

        &:nth-child(2) {
          width: 120px;
          height: 50px;
        }

        &:nth-child(3) {
          width: 15%;
        }
      }
    }

    tbody {
      display: block;
      height: calc(100vh - 200px);
      overflow: auto;

      td {
        text-align: center;
        color: #2b3674;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin-right: 24px;
        padding: 5px;

        &:first-child {
          width: 30px;
          color: #a3aed0;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
        }

        &:nth-child(2) {
          width: 120px;
          height: 90px;

          .thumbnail {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }

        &:nth-child(3) {
          width: 15%;
        }

        &.videoTitle {
          text-align: left;
        }

        &:last-child {
          a {
            width: fit-content;
            background: #f4f7fe;
          }
        }
      }
    }

    thead,
    tbody > tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
}

.seeMore {
  margin-right: 0;

  .seeMoreLink {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2px 1px 2px 6px;
    border-radius: 11px;

    p {
      color: #1b2559;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 8.5px;
      text-decoration-line: underline;
      margin-bottom: 0;
      text-wrap: nowrap;
    }

    span {
      padding: 0 6px;
      border-radius: 50%;
      margin-left: 2px;

      svg {
        width: 9px;
        height: 9px;
      }
    }
  }
}
