.container {
  .tabs,
  .reachTabs,
  .earningTabs {
    width: 100%;

    display: flex;

    &:has(div.tabWithoutChart) {
      gap: 50px;
    }

    > div.tabWithChart {
      width: 100%;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background-color: #ffffff;

      p {
        color: #1b2559;
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        text-align: center;
        // margin-bottom: 20px;
      }

      h1 {
        color: #1b2559;
        font-family: Inter, sans-serif;
        font-size: 33.265px;
        font-style: normal;
        font-weight: 900;
        line-height: 38.809px;
        margin-bottom: 0;
      }
    }

    > div.tabWithoutChart {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 50px;

      border-radius: 21.283px;
      background: rgba(225, 233, 248, 0.5);
      box-shadow: -1px 5px 16.5px -2px rgba(32, 86, 206, 0.3);

      .iconWrapper {
        width: 70px;
        height: 70px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        background-color: #fff;
      }

      > p {
        color: #1b2559;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 36px;
        font-weight: bold;

        margin: 28px 0;
      }

      > h1 {
        color: #1b2559;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 45px;
        font-weight: bold;
      }
    }

    .activeTab {
      border-top: 7px solid #074dff;
      background: #ffffff;
    }
  }

  .reachTabs {
    grid-template-columns: 1fr 1fr;
  }

  .earningTabs {
    grid-template-columns: repeat(5, 1fr);

    div {
      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 17.807px;
        margin-bottom: 9px;
      }

      h1 {
        font-size: 21.939px;
        font-weight: 900;
        line-height: 25.596px;
        margin-bottom: 0;
      }

      .negativeStatistic {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  .chartContainer {
    border-radius: 0px 0px 21.283px 21.283px;
    background: #fff;
    padding: 10px 24px;
  }

  .earningsChart {
    border-radius: 0px;
  }

  .table {
    > div {
      background-color: #fff;
    }
  }

  .content {
    background-color: #fff;
    border-bottom: 1px solid #dbe0f0;
    border-top: 1px solid #dbe0f0;
    padding: 30px 10px 40px 10px;
  }
}
