.container {
  grid-column: 1/3;
  display: flex;
  align-items: center;
  margin-bottom: 9px;

  span {
    color: #1b2559;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .tooltip {
    display: flex;
  }

  > div {
    position: relative;
    cursor: pointer;

    p {
      min-width: 100px;
      position: absolute;
      left: 30px;
      bottom: 0;
      visibility: hidden;
      border-radius: 6px;
      background: #1b2559;
      padding: 4px 12px;
      z-index: 1;
      color: #a3aed0;
      font-family: Inter, sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0.3px;
    }

    svg {
      margin-left: 12px;
    }
  }

  &:hover {
    p {
      visibility: visible;
    }
  }
}
