.chartContainer {
  background: #fff;
  padding: 30px 30px 0 30px;

  .chartInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .description {
      width: 100%;

      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .totalEarnings {
        width: 100%;

        p {
          flex-shrink: 0;

          color: #a3aed0;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 0;
        }

        .viewAll {
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            margin-right: 10px;
            color: #074dff;
            font-size: 16px;
          }
        }
      }

      > span {
        display: flex;
        align-items: center;

        h1 {
          color: #1b2559;
          font-family: Inter, sans-serif;
          font-size: 34px;
          font-style: normal;
          font-weight: 700;
          line-height: 42px;
          margin-bottom: 0;
        }

        p {
          color: #05cd99;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }

        svg {
          margin: 0 7px;
        }
      }

      > div {
        display: flex;
        align-items: center;

        p {
          color: #05cd99;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }

        .iconContainer {
          border-radius: 50%;
          width: 11px;
          height: 11px;
          background-color: #05cd99;
          margin-right: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .chartIcon {
      border-radius: 7px;
      background: #f4f7fe;
      padding: 5px 4px 4px 5px;

      &:hover {
        cursor: pointer;
        background: #074dff;
        svg {
          path {
            fill: #f4f7fe;
          }
        }
      }
    }
  }
}
