.dashboardWrapper {
  width: 100%;

  display: flex;
  flex-direction: column;

  .dashboardSearch {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 33px;

    .dashboardGreeting {
      margin-right: 26px;

      p {
        color: #707eae;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 0;
      }

      h1 {
        text-wrap: nowrap;
        color: #2b3674;
        font-family: Inter, sans-serif;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        margin-bottom: 0;
      }
    }

    .searchWrapper {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      color: #8f9bba;
      position: relative;

      svg {
        position: absolute;
        top: 17px;
        left: 20px;
      }

      input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 10px;
        padding: 13px 42px;

        &:focus {
          border: none;
        }

        &::placeholder {
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.24px;
        }
      }
    }
  }

  .widgetsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 20px;

    > div {
      border-radius: 21.28px;
      color: #1b2559;

      &:first-child {
        grid-column: 1/5;
      }

      &:nth-child(2) {
        grid-column: 5/9;
      }

      &:nth-child(3) {
        grid-column: 9/13;
      }

      &:nth-child(4) {
        grid-column: 1/5;
      }

      // VUZ Pay Widget
      &:nth-child(5) {
        grid-column: 5/9;
        max-height: 100px;
      }

      &:nth-child(6) {
        grid-column: 1/5;
      }

      &:nth-child(7) {
        grid-column: 5/13;
      }

      &:nth-child(8) {
        grid-column: 1/9;
      }

      &:nth-child(9) {
        grid-column: 9/13;
      }
    }

    .tableVideoTitle {
      table {
        thead {
          th {
            &:nth-child(2) {
              height: 40px;
            }

            &:nth-child(3) {
              width: 25%;
            }
          }
        }

        tbody {
          td {
            &:nth-child(2) {
              img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                max-width: 130px;
              }
            }

            &:nth-child(3) {
              width: 25%;
              line-height: 16px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
