@use '../../../../styles/mixins' as *;

.uploadsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 1024px) {
    align-items: center;
  }

  .uploadsPageTitle {
    color: #001e36;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;

    margin-bottom: 60px;
  }

  .uploadsList {
    .uploadedVideo {
      .uploadedVideoInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin-bottom: 18px;

        .uploadDate {
          color: #001e36;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .statusLabel {
          padding: 5px 12px;
          border-radius: 5px;

          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          &.rejected {
            color: #ef3451;
            background: rgba(239, 52, 81, 0.2);
          }

          &.approved {
            color: #0a1;
            background: rgba(51, 209, 66, 0.2);
          }

          &.inReview {
            color: #d37200;
            background: rgba(219, 138, 17, 0.2);
          }
        }
      }

      .uploadedVideoParts {
        display: flex;

        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 36px;
        gap: 40px;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
        }

        .videoBoxOuterDiv {
          height: 300px;

          flex-grow: 1;
          flex-basis: 0;

          padding: 8px;

          background-color: #fff;
          border-radius: 12px;
          position: relative;
          border: 2px solid white;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @include small-screens {
            height: 200px;
          }

          .filePreview {
            height: 100%;

            display: flex;
            flex-direction: column;

            padding: 10px 27px;

            @media screen and (max-width: 768px) {
              padding: 10px;
            }

            > img {
              margin-right: 5px;
            }

            > span {
              margin-bottom: 15px;

              color: #001e36;
              text-align: center;
              font-size: 12px;
              word-break: break-all;
            }

            .previewImageContainer {
              display: flex;
              align-items: center;
              justify-content: center;

              margin: auto;

              overflow: hidden;

              .imageCanvas {
                width: 300px;
                aspect-ratio: 16 / 9;
              }

              .previewImage {
                height: 100%;
                max-width: 100%;
              }

              .loader {
                position: absolute;
                animation: rotation 1s linear infinite;
              }
            }
          }
        }
      }
    }
  }
}
