.container {
  height: 100%;
  max-height: 330px;

  padding: 24px;
  border-radius: 16px;
  background: #fff;

  .tableWrapper {
    width: 100%;
    height: 90%;

    th {
      color: #1b2559;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }

    tbody {
      display: block;
      height: 225px;
      overflow: auto;

      td {
        text-wrap: nowrap;
        padding: 13px 0;
        color: #a3aed0;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: center;

        &:first-child {
          color: #1b2559;
        }
      }
    }

    thead,
    tbody > tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
}
