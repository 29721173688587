.container {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 28px;

  .headerTitle {
    color: #2b3674;
    font-family: Inter, sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
  }

  > div {
    .header {
      color: #1b2559;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      grid-column: 1/3;
      margin-bottom: 9px;
      display: flex;
      align-items: center;

      .tooltipContainer {
        position: relative;
        cursor: pointer;

        .tooltip {
          min-width: 100px;
          position: absolute;
          left: 30px;
          bottom: 0;
          visibility: hidden;
          border-radius: 6px;
          background: #1b2559;
          padding: 4px 12px;
          z-index: 1;
          color: #a3aed0;
          font-family: Poppins, sans-serif;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0.3px;
        }

        &:hover {
          .tooltip {
            visibility: visible;
          }
        }

        svg {
          margin-left: 12px;
        }
      }
    }

    &:first-child {
      grid-column: 1/13;

      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        h1 {
          color: #2b3674;
          font-family: Inter, sans-serif;
          font-size: 34px;
          font-style: normal;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: -0.68px;
          margin-bottom: 8px;
        }

        p {
          color: #a3aed0;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.28px;
          margin-bottom: 8px;
        }
      }
    }

    &:nth-child(2) {
      grid-column: 1/10;

      height: 330px;
    }

    &:nth-child(3) {
      grid-column: 10/13;

      height: 330px;
    }

    &:nth-child(4) {
      grid-column: 1/10;

      height: 330px;
    }

    &:nth-child(5) {
      grid-column: 10/13;

      height: 330px;
    }

    &:nth-child(6) {
      grid-column: 1/7;

      height: 200px;
    }

    &:nth-child(7) {
      grid-column: 7/13;

      height: 200px;
    }
  }
}
