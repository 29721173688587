@mixin gradient-loading {
  background: linear-gradient(270deg, #b6ccff -22.68%, #dce6ff 27.85%, #ecf1ff 99.66%);
  background-size: 200% 100%;
  animation: loading 3s linear infinite;
}

.loading.loading.loading.loading.loading {
  @include gradient-loading;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
