.tooltip {
  cursor: pointer;
  display: flex;
  position: relative;

  span {
    background: #1b2559;
    border-radius: 6px;
    bottom: 20px;
    color: #a3aed0;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    left: 50%;
    letter-spacing: 0.3px;
    padding: 4px 12px;
    position: absolute;
    transform: translateX(-50%);
    visibility: hidden;
    z-index: 1;
  }

  &:hover {
    span {
      visibility: visible;
    }
  }
}
