.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  h1,
  p {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    margin: 0 10px 0 0;
    color: #1b2559;
  }

  p {
    font-weight: 400;
  }

  .progressBarContainer {
    width: 100%;
    height: 6px;
    border-radius: 80px;
    margin: 8px;

    .progressBar {
      height: 6px;
      border-radius: 80px;
    }
  }
}
