.container {
  height: 100%;
  max-height: 330px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 24px;
  border-radius: 16px;
  background: #fff;

  .header {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    > h1 {
      color: #1b2559;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 0;
    }

    > ul {
      margin-left: 37px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0;
      padding: 0;

      list-style: none;

      li {
        display: flex;
        align-items: center;

        margin-right: 30px;
        color: #1b2559;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        .dot::before {
          content: '•';
          margin-right: 0.5em;
        }
      }
    }
  }
}
