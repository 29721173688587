.container {
  align-items: center;
  background: #f6f8fa;
  border-radius: 8px;
  border: 1px solid #eceef0;
  display: inline-flex;
  padding: 16px 12px;

  > span {
    color: #1b2559;
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
  }

  > button {
    align-items: center;
    background: none;
    border: 0;
    color: #074dff;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
    justify-content: center;
    width: 100px;

    > svg {
      margin-right: 2px;
    }
  }
}
