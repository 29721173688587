.customTooltipWrapper {
  position: absolute;
}

.customTooltip {
  padding: 7px 9px;
  background: #1b2559;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  padding: 10px;
  min-width: 67px;
  min-height: 39px;
  opacity: 0;

  p {
    color: white;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0.02em;
    margin-bottom: 0;
  }

  .statsDescription {
    font-size: 11px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0px;
    margin-bottom: 5px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: #1b2559 transparent transparent transparent;
  }
}
