@use '../../../../styles/mixins' as *;

.uploadSection {
  width: 100%;

  overflow: auto;

  .uploadPageTitle {
    color: #001e36;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;

    margin-bottom: 50px;
  }

  .upload {
    display: flex;
    justify-content: flex-start;

    margin-bottom: 150px;

    @include small-screens {
      margin-bottom: 0;
    }

    > form {
      max-width: 360px;

      flex: 1 1 100%;

      &:not(:last-of-type) {
        margin-right: 60px;
      }

      .stepDiv {
        .stepTitle {
          display: flex;
          align-items: center;

          color: #001e36;
          font-size: 18px;
          font-weight: 700;

          margin-bottom: 10px;

          > div {
            width: 20px;
            height: 20px;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 50%;
            background: #d9d9d9;

            color: #000;
            font-size: 12px;
            font-weight: 700;

            margin-right: 8px;
          }
        }

        .videoBoxOuterDiv {
          height: 300px;

          flex-grow: 1;
          flex-basis: 0;

          padding: 8px;

          background-color: #fff;
          border-radius: 12px;
          position: relative;
          border: 2px solid white;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @include small-screens {
            height: 200px;
          }

          .videoBoxInnerDiv {
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            border-radius: 8px;
            border: 4px dashed #e2e2e2;

            padding: 20px;

            .stepIconWrapper {
              width: 80px;
              height: 80px;

              margin: auto;

              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;

              border-radius: 50%;
              background: rgba(207, 207, 207, 0.6);

              @include small-screens {
                width: 60px;
                height: 60px;
              }

              .videoBoxImg {
                max-height: 70px;

                @include small-screens {
                  width: 40px;
                }
              }
            }

            .infoText {
              color: #a5a5a5;
              text-align: center;
              font-size: 12px;

              .bold {
                font-weight: 700;
              }
            }
          }

          .filePreview {
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: 10px 27px;

            .videoEditBtn {
              background: transparent;
              border: none;

              color: #074dff;
              text-align: center;
              font-size: 16px;
              font-weight: 700;

              > img {
                margin-right: 5px;
              }

              > span {
                vertical-align: middle;
              }
            }

            > span {
              color: #001e36;
              text-align: center;
              font-size: 12px;

              word-break: break-all;
            }

            .previewImageContainer {
              display: flex;
              align-items: center;
              justify-content: center;

              margin: 15px auto;

              overflow: hidden;

              .imageCanvas {
                width: 300px;
                aspect-ratio: 16 / 9;
              }

              .previewImage {
                height: 100%;
                max-width: 100%;
              }

              .loader {
                position: absolute;
                animation: rotation 1s linear infinite;
              }
            }
          }
        }

        .uploadBtnContainer {
          margin-top: 40px;

          @include small-screens {
            margin-top: 20px;
          }

          .stepBtn {
            width: 100%;
            padding: 18px 0;

            border: none;
            border-radius: 5px;
            background: #074dff;

            color: #fff;
            text-align: center;
            font-size: 16px;
            font-weight: 700;

            cursor: pointer;

            white-space: nowrap;

            @include small-screens {
              padding: 10px 0;
            }

            &:disabled {
              border: none;
              color: #92989c;
              background: #e2e2e2;
              cursor: not-allowed;
            }
          }

          .uploadProgress {
            display: flex;
            justify-content: center;

            position: relative;

            color: #074dff;
            text-align: center;
            font-size: 16px;
            font-weight: 700;

            .progressContainer {
              position: absolute;

              display: flex;
              flex-direction: column;
              align-items: center;

              top: 30px;
              z-index: 99;
              padding: 8px;

              .progressBarContainer {
                display: flex;
                align-items: center;
                justify-content: center;

                :global(.progress) {
                  width: 180px;
                }

                .cancelUploadBtn {
                  display: flex;

                  margin: 0 0 0 10px;
                  padding: 0;
                  background-color: transparent;
                  border: none;
                }
              }

              .uploadInfo {
                color: #074dff;

                font-family: Inter;
                font-size: 12px;
                font-weight: 400;

                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }

  .submitForReviewBtn {
    width: 350px;

    padding: 18px;
    margin-top: 40px;

    border: none;
    border-radius: 5px;
    background: #074dff;

    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;

    @include small-screens {
      margin-top: 0;
    }
  }

  .progressAlertWrapper {
    width: 100%;

    display: flex;
    justify-content: center;
  }
}

.uploadInfoModalOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);

  top: 0;
  left: 0;
  z-index: 99;

  .uploadInfoModal {
    width: 500px;

    padding: 60px 24px 36px 24px;
    background-color: white;
    border-radius: 8px;

    > h4 {
      color: #001e36;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 25px;
    }

    > span {
      display: block;

      color: #6e6e6e;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 85px;
    }

    .controls {
      width: 100%;

      display: flex;
      justify-content: flex-end;

      > button {
        width: 180px;
        border: none;

        padding: 18px;
        border-radius: 5px;

        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        background: #074dff;

        margin-right: 10px;

        cursor: pointer;

        &.secondary {
          color: #6e6e6e;
          border: 1px solid #d9d9d9;
          background: #fff;
        }
      }
    }
  }
}

:global(.progress-alert) {
  position: fixed;
  bottom: 30px;
}

:global(.progress) {
  height: 10px !important;
}

:global(.progress-bar-striped) {
  background-image: none !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
