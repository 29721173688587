.singleStatsWidgetContainer {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 20px 16px;

  .iconContainer {
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }

  .statsContainer {
    position: absolute;
    right: 12px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #05cd99;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  .negativeStatistic {
    p {
      color: #b22222;
    }

    svg {
      transform: rotate(90deg);

      path {
        fill: #b22222;
      }
    }
  }

  .statsDescription {
    width: 65%;

    p {
      color: #a3aed0;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 0;
    }

    h1 {
      color: #1b2559;
      font-family: Inter, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 0;
    }
  }
}

.activityContainer {
  background: linear-gradient(135deg, #868cff 0%, #074dff 100%);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .activityDescription {
    margin: 0 22px;

    p {
      color: #e9edf7;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.32px;
      margin-bottom: 0;
    }

    h1 {
      color: #fff;
      font-family: Inter, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.48px;
      margin-bottom: 0;
    }
  }

  .iconContainer {
    display: flex;
    justify-content: flex-end;
  }
}
