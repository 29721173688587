.myVideosContainer {
  width: 100%;

  .aboveSection {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 15px;

    span {
      h1 {
        color: #2b3674;
        font-family: Inter, sans-serif;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
      }

      p {
        color: #a3aed0;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 0;
      }
    }

    > div {
      position: relative;
      border-radius: 10px;
      background: #eaeffb;
      padding: 13px 42px;
      width: 391px;

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        color: #8f9bba;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &::placeholder {
          color: #8f9bba;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        &:focus {
          outline: none;
        }
      }

      svg {
        position: absolute;
        top: 20px;
        left: 20px;
      }
    }
  }
}
